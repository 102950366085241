import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'
import Seo from '../components/seo'
import ProductCard from '../components/cards/product'
import moment from 'moment'
import { product } from 'prelude-ls'
import ReadyToGetStarted from '../components/ready-to-get-started'

class ShopPage extends Component {

  state = {
    count: 0,
    results: this.props.data.allWpProduct.nodes,
    filters: [],
    colours: [],
    materials: [],
    sort: null,
    colour: null,
    material: null,
    pathname: null
  }

  componentDidMount() {
    this.updateList()
    this.buildFilters()
    this.setState({pathname: window && window.location.pathname})
  }

  toggleFilters = (item) => {
    let { filters } = this.state
    filters[item] = !filters[item]
    this.setState({ filters })
  }

  buildFilters() {
    let products = this.props.data.allWpProduct.nodes
    let colours = [],
        materials = []
      
    products.length > 0 && products.map((el, i) => {
      el.shopifyProduct.variants.forEach((variant, v) => {
        if (variant.title !== 'Default Title') colours.push(variant.title)
      })
      el.materials?.nodes.length && el.materials.nodes.forEach((material, m) => {
        materials.push(material.name)
      })
    })

    colours = [...new Set(colours)]
    materials = [...new Set(materials)]

    this.setState({
      colours,
      materials,
    })
  }

  handleSortChange = (option) => {
    let { name, value } = option.currentTarget
    let state = {}
    state[name] = value
    this.setState(state, this.sortList(value))
  }

  handleChange = (option) => {
    let { name, value } = option.target
    let state = {}
    state[name] = value
    this.setState(state, this.updateList)
  }

  clearColour = () => {
    let state = {
      colour: null,
    }
    this.setState(state, this.updateList)
    if ( document ) {
      let radio = document.querySelector('input[name="colour"]:checked')
      radio.checked = false
    }
  }

  clearMaterial = () => {
    let state = {
      material: null,
    }
    this.setState(state, this.updateList)
    if ( document ) {
      let radio = document.querySelector('input[name="material"]:checked')
      radio.checked = false
    }
  }

  sortList = (sort) => {
    let { results } = this.state
    if (results.length === 0) return false
    switch (sort) {
      case 'highest-price':
        results.sort((a, b) => parseFloat(a.shopifyProduct.variants[0].price) < parseFloat(b.shopifyProduct.variants[0].price) ? 1 : -1)
        break
      case 'lowest-price':
        results.sort((a, b) => parseFloat(a.shopifyProduct.variants[0].price) < parseFloat(b.shopifyProduct.variants[0].price) ? -1 : 1)
        break
      case 'oldest':
        results.sort((a, b) => moment(a.date).isBefore(b.date) ? -1 : 1)
        break
      default:
        // Sort by date
        results.sort((a, b) => moment(a.date).isBefore(b.date) ? 1 : -1)
        break
    }
    this.setState({results})
  }

  updateList = () => {
    let { colour, material, results } = this.state
    const products = this.props.data.allWpProduct.nodes
    if (products.length > 0) {
      results = products.filter((el, i) => {
        if ( material ) {
          let materials = el.materials.nodes
          if ( materials.length > 0 ) {
            for(let x = 0; x < materials.length; x++) {
              if (materials[x].name === material) return true
            }
          }
          return false
        }
        if ( colour ) {
          let colours = el.shopifyProduct.variants
          if ( colours.length > 0 ) {
            for(let x = 0; x < colours.length; x++) {
              if (colours[x].title === colour) return true
            }
          }
          return false
        }
        return true
      })
    }
    let count = results.length
    this.setState({
      results,
      count
    })
  }

  render() {
    let page = this.props.data.wpPage
    let { breadcrumbs } = this.props.pageContext
    let { results, count, filters, colours, materials, colour, material, pathname } = this.state

    return (
      <>
        <Seo title={`Shop - Home Design Co.`} bodyClass='shop-archive' description={`All Products`} />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/shop/'>Shop Now</Link></li>
            </ul>
          </div>
        </section>
        <section className='products'>
          <div className='products__inner'>
            <div className='products__sidebar'>
              <h4>Filter Products</h4>
              <div className='products__filters'>
                <div className={`products__filter ${filters[0] && 'active'}`}>
                  <div className='products__filter-heading'>
                  <span onClick={() => this.toggleFilters(0)}>Colour</span>
                    { colour && <button type='button' className='clear' onClick={this.clearColour}>Clear</button> }
                  </div>
                  <ul>
                    { colours?.length > 0 && colours.map((el, i) => (
                      <li className='form__checkbox' key={i}>
                        <input type='radio' name='colour' id={`colour-${el}`} value={el} onChange={this.handleChange} />
                        <label htmlFor={`colour-${el}`}>{el}</label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={`products__filter ${filters[1] && 'active'}`}>
                  <div className='products__filter-heading'>
                    <span onClick={() => this.toggleFilters(1)}>Material</span>
                    { material && <button type='button' className='clear' onClick={this.clearMaterial}>Clear</button> }
                  </div>
                  <ul>
                    { materials?.length > 0 && materials.map((el, i) => (
                      <li className='form__checkbox' key={i}>
                        <input type='radio' name='material' id={`material-${el}`} value={el} onChange={this.handleChange} />
                        <label htmlFor={`material-${el}`}>{el}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='products__main'>
              <div className='products__sort-wrap'>
                <p>{count} Products</p>
                <div className='products__sort'>
                  <span>Sort by</span>
                  <div className='products__select form__select'>
                    <select name='sort' onChange={this.handleSortChange}>
                      <option value='newest'>Most Recent</option>
                      <option value='oldest'>Oldest</option>
                      <option value='highest-price'>Highest Price</option>
                      <option value='lowest-price'>Lowest Price</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='products__items'>
                { results?.length > 0 && results.map((el, i) => {
                  return (
                    <div className='products__item' key={i}>
                      <ProductCard {...el} addVariantToCart={this.props.addVariantToCart} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <ReadyToGetStarted />
      </>
    )
  }
}

export const shopQuery = graphql`
  query {
    allWpProduct(
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        slug
        date
        materials {
          nodes {
            name
            slug
          }
        }
        shopifyProduct {
          images {
            src
            width
            height
          }
          variants {
            admin_graphql_api_id
            title
            price
          }
        }
      }
    }
  }
`

export default ShopPage
